.cart-items-wrap{
  // background: teal;
  padding: 0px 20px 0px 0px;
  margin-bottom: 10px;
  border-bottom: 1px solid var(--gray);
  cursor: pointer;
}
.cart-items-wrap:first-of-type{
  // border-top: 1px solid var(--gray);
  margin-top: 30px;
}
    .summary {
      margin-top: 2rem;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
  
      .card {
        padding: 1rem;
        .text {
          display: flex;
          justify-content: space-between;
          align-items: center;
          line-height: 30px;
          h3 {
            color: var(--mag);
          }
        }
        button {
          margin-top: 5px;
        }
      } 
    }

  .count {
    display: flex;
    align-items: center;
    justify-content: flex-start;

  }
.count-total{padding: 8px;color:var(--grey)}
.cartColor{
  width: 15px;
  height: 15px;
  border-radius: 50%;
  list-style-type: none;
}
  @media screen and (max-width:1000px) {
    .thead{
      display: none;
    }
    .summary{
      flex-direction: column;
      align-items:flex-end!important;
    }
  }

  