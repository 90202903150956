
.navs{
display: flex;
flex-direction: column;
margin-top: 40px;

ul{
 display: flex;
 flex-direction: column;
 gap: 0;  
 .n-icons{
    margin-right: .5rem;
 
}

 li{
    width: 100%;
    border-bottom: 1px solid var(--gray);
    :hover{
    background: #f7f7f7;
    // border-top-right-radius: 3em;
    // border-bottom-right-radius: 3em;
    border-bottom: 1px solid var(--black);
    color: var(--black);
    transition: .2s ease-in-out;
    }
    a{
        display: flex;
        align-items: center !important;
        flex-direction: row;
        align-self: center;
        color: var(--fblack);
        padding:  16px ;
        font-size: 14px;

    }  .active{
        color: var(--black);
        border-right: 3px solid var(--black);
    }
 }
}
}
