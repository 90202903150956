.card {
    width: 100%;
    max-width: 500px;
    padding: 1rem;
    border: 1px solid var(--lmag);
    margin-bottom: 5px;
  }
  h3 {
    font-weight: 400;
  }
  
  .text {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h3 {
      color: var(--mag);
    }
  }
  