.infoboxes{
width: 100%;
background: #f7f7f7;
display: flex;
align-items: center;
justify-content: center;
gap:2rem;
padding: 2rem;
color: #333;
}
.infobox{
display: flex;
align-items: center;
min-height: 80px;
gap:.7rem;
}
.right-info{
display: flex;
flex-direction: column;
// gap:.4rem;
}
.right-info > h4{
font-weight: 600;
font-size: 16px;
}
.right-info > p{
font-size: 14px;
}

// Page Heading
.page-heading{
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
height: 200px;
gap:1rem;
h3{
 font-size: 16px;
 letter-spacing: 2px;
 font-weight: bolder;
 color: var(--fblack);
}
h1{
letter-spacing: 1px;
font-weight: 600;
text-align: center;
}
}
.page-heading::after{
content: "";
width: 80px;
display: flex;
align-items: center;
justify-content: center;
border: 2px solid var(--mag);
border-radius: 3em;
margin-top: 20px;
}
// 
.home-btn,.home-btn >a{
min-height: 50px;
display: flex;
align-items: center;
justify-content: center;
gap: .5rem;
margin-bottom: 2rem;
// border-radius: .3em;
min-width: 140px;
}
.home-btn >a:hover{
transition: 0.6s ease;
border-color: var(--black) !important;
color: var(--black);
}
.home-btn>a{
     // background:#f7f7f7;
     padding: 12px;
     font-weight: 500;
     font-family:var(--font-family2);
     color:var(--fblack);
     border: 1px solid var(--gray);
     border-radius: 3em;
     font-size: 14px;
}
@media screen and (max-width: 850px) {
.infoboxes{
     flex-direction: column;
     align-items: flex-start;
}
}