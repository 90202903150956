.admin{
    // background: #f7f7f7;
    background: #fff;
    min-width: 100vw;
    max-width: 100vw;
    display: flex;
    

.navbar{
    // min-width: 25%;
    min-width: 300px;
    max-width: 300px;
    min-height: 100vh;
    border-right: 1px solid var(--gray);
    background: #fff;
}
.content{
    width: 100%;
    padding: 12px;
    box-sizing: border-box;
    margin: 0;
}
}
@media  screen and (max-width:1000px) {
    .navbar{
        // background: #000;
        position: fixed;
        left: 0;
        top: 0;
        z-index: 200;
        height: 100vh;
    }
    .close-bg{
    position: fixed;
    top: 0;
    right: 0;
    background: rgba(0,0,0,0.8);
    width: calc(100vw - 300px);
    height: 100vh;
    z-index: 101;
    }
    }

