.product {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow-x: hidden !important;
  a{
  color: var(--grey);
  background: none!important; 
    &:hover{
      color: var(--black);
      transition: .4s ease;
    }}
  b{font-weight: normal;}
    .card {
      padding: 2rem 1rem;
      margin-top: 1rem;
    }
    .review {
      border-top: 1px solid #ccc;
      padding-top: 5px;
      padding-bottom: 5px;
    }
    .details {
      padding-top: .5rem;
      display: flex;
      width: 85vw;
      max-width: 85vw;
  
      .img {
        width: 50%;
        margin-right: 1rem;
        height: 600px;
        overflow: hidden;
        .pImg {
          width: 100%;
          height: 500px;
          object-fit: cover;
          transform: translateX(3rem);
          animation: slide 0.5s forwards;

        }
      }
  
      .content {
        width: 50%;
        // padding: 0 5px;
        color: var(--fblack);

        p{
  
          color: var(--grey);
          
        }
        .skupart{
        margin-top: 8px;
        }
        .price {
          p{font-size: 18px;color:var(--black)}
margin-bottom: 8px;
del{
  margin-left: 8px;
  margin-right: 8px;
}
        }
      }
    }
  }
  .count {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
.count-total{padding: 8px;}
  @keyframes slide {
    to {
      transform: translateX(0);
    }
  }
  
  .smallImg {
    width: 100%;
    max-height: calc(100px - 8px);
    display: flex;
    justify-content: flex-start;
    overflow-x: scroll;
    overflow-y: hidden;
    img {
      width: 92px;
      height: 92px;
      object-fit: cover;
      cursor: pointer;
      margin-right: 5px;
    }
  }
  
  .property {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
    padding-bottom: 5px;
  }
.breadcrumbs{
width: 85%;
font-size: 14px !important;
overflow: hidden;
justify-content: flex-start !important;

 }
  .unordered-color{
    display: flex ;
    flex-wrap: wrap;
    flex-shrink: 0;
    justify-content: flex-start !important;
    width: 100%;
    gap: 0;
    margin-bottom: 10px;
    li{
    border: 1px solid var(--grey);
    }

  }
  .size-orders{
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 10px;

  li{
    min-width: 50px;
    height: 30px;
    border-radius: 3em;
    border:1px solid var(--gray);
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    color: #444;

    &:hover{
      border-color: #000;
      transition: .6 ease;
      
    }
  }
  }
  .size{
    margin-bottom: 10px;
    color: var(--black) !important;
  }
  .sizeHover{
    border-color: var(--black) !important;
    transition: .5s ease;
  }
  .button-order{
    height: 50px;
    display: flex;
    align-items: center;

    button{
      border-radius: 3em;
      width: 150px;
      text-transform: uppercase;
      color: var(--white);

    }
  }
.product-review-section{
  width: 85%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
.review-desc{
  font-size: 14px;
  text-transform:capitalize;
}

@media screen and (max-width: 1024px) {
.details,
.breadcrumbs,
.product-review-section{
width: 96vw !important;
max-width: 96vw !important;
}
}
@media screen and (max-width: 775px) {
  .details {
    .img {    
      .pImg {
        width: 100%;
        height: 500px !important;
  }}}
  .smallImg {
    img {
      width: 80px;
      height: 80px;
    }
  }
}

@media screen and (max-width:700px) {
.details{
  flex-direction: column;
  .img {    
    .pImg {
      height: 500px !important;
}}
  .img,.content{
    width: 100% !important;
  }
}
.smallImg {
  img {
    width: 60px;
    height: 60px;
  }
}
.breadcrumbs{
  display: none;
}
}
@media screen and (max-width:600px) {
  .details{
    .img {    
      height: 480px !important;
      .pImg {
        height: 400px !important;
  }}}}
  @media screen and (max-width:550px) {
    .details,
    .product-review-section{
    width: 92vw !important;
    max-width: 92vw !important;
    }
  }