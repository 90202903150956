.notfound{
position: relative;
top:0;
left: 0;
display: flex;
flex-direction: column;
align-items: center;
margin-top: 13vh;
// justify-content: center;
// min-height: calc(100vh - 6rem);
min-height:100vh;
width: 100%;
gap: 1rem;
padding: 0 2rem 0 2rem;
}
.nf-img-cont{
width: 100%;
display: flex;
align-items: center;
justify-content: center;
img{
display: flex;
align-self: center;
max-width: 400px;
width: 100%;
// aspect-ratio: 16/9;
}

}
.nf-text-cont{
    // background: #000;
    color: var(--grey);
    font-size: 14px;
    h3{
        font-weight: 600;
        margin-bottom: 10px;
    }
    p{
        margin-bottom: 5px;
    }
    }
    .global-links{
     display: flex;
     align-items: center;
     justify-content:flex-start;
     gap: 1em;
     height: 40px;
  > *{
    background: var(--gray);
    padding: 12px;
    margin-top: 1rem;
    min-width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--fblack);
    border-radius: 3px;
    font-weight: 600;
  }
  :hover{
    opacity: 0.7;
    transition: .7 ease-in-out;
}
    }
    @media screen and (max-width: 400px) {
        .global-links{
            flex-direction: column;
            align-items: flex-start;
        }
    }