.wallet-transactions{
background: #fff;
padding: 20px;
}
.wallet-transactions > h3{
margin-bottom: 20px;
}
table{
margin-top: 20px;
border-spacing: 10px;
cursor: pointer;
// border:1px solid var(--gray);
} 
thead{
border: none;
height:50px;
font-size: 14px;
color: var(--grey);
background: #f7f7f7;
// border:1px solid var(--gray)

}
tr,th{
border: none;
// font-weight: 600;
font-size: 14px;
border-bottom: 1px solid var(--gray);
vertical-align: middle;
text-align: left;
padding: 12px;
color: grey;

}
td{
    padding: 20px 0 20px 12px;
}
.debit-transact{
color: tomato;
// font-weight: 600;
padding: 8px;
border:1px solid tomato;
border-radius: .3em;
}
.credit-transact{
color: green;
// font-weight: 600;
padding: 8px;
border:1px solid green;
border-radius: .3em;
}
.screen-wrapper{
display: none;
}
@media screen and (max-width:1000px) {
 .hideforscreen{
    display: none;
 }
 .screen-wrapper{display: flex;flex-direction: column;}
 // Screen Design
.screen-cont{
    border: 1px solid var(--gray);
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 8px;
    border-radius: .3em;
    margin-bottom: 20px;
    height: 60px;
    color: var(--fblack);
    }
    .mobile-trans{
        width: 70%;

        span:first-child{
         margin-bottom: 4px;
         font-weight: 500;
         text-transform: uppercase;
        }
        span:last-of-type{
        font-size: 12px;
        }
    }

    .mobile-transac{
        // width: 70%;

        span:first-child{
         margin-bottom: 4px;
        }
        span:last-of-type{
        font-size: 12px;
        color: green;
        }
    }
}
