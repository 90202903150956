.map{
width: 100%;
display: flex;
margin: 20px 12px 20px 12px;
.left-map{
width: 50%;
overflow: hidden;
margin-right: 20px;
h4{
    color: #444;
    margin: 10px 0 10px 0;
    font-weight: 600;
}
}
.right-map{
width: 50%;
overflow: hidden;
margin-right: 20px;

h4{
    color: #444;
    margin: 10px 0 10px 0;
    font-weight: 600;
}
}
}
.contact{
margin: 12px;
padding: 12px;
margin-top: 20px;
width: 100%;
box-shadow: var(--box-shadow);
.left-contact{
width: 50%;
form{
input[type=text],
input[type=number],
input[type=email],textarea{
width: 100%;
height: 42px;
border-radius: .3em;
outline: none;
background: none;
border: 1.5px solid var(--gray);
margin-top: 20px;
padding-left: 12px;
}
input[type=submit]{
margin-top: 20px;
}
}
h3{
    color: #444;
    margin: 10px 0 10px 0;
    font-weight: 600;
}
}
.right-contact{
width: 50%;
display: flex;
flex-direction: column;
margin-left: 30px;
margin-top: 10px;
h4{
    color: #444;
    margin: 10px 0 10px 0;
    font-weight: 600;
}

.contact-address{
height: 40px;
display: flex;
align-items: center;
font-size: 14px;
color: var(--grey);
color: #444;
text-transform: capitalize;

}
}
}

@media screen and (max-width: 600px) {
.map,.contact{
flex-direction: column !important;
.left-map,.right-map,.left-contact,.right-contact{
width: 94% !important;
}
.right-contact{
margin-left: 0 !important;
}
}

}