.info-box{
 min-width: 300px !important;
 height: 7rem;
//  max-width: 22rem;
 margin-right: 1rem;
 margin-bottom: 1rem;
 display: flex;
 justify-content: flex-start;
 align-items: center;
 transform: translateY(0);
 transition: all .3s;
 border-radius: .3em;
box-shadow: var(--box-shadow);
 &:hover{
    cursor: pointer;
   //  transform: translateY(-5px);
 }
 .info-icon{
    padding: 0 2rem;
    color: #333;
 }
 .info-text > * {
    color: #fff;
 }
 p{
   font-weight: 600 !important;
   text-transform: capitalize;
 }
}
