.categories {
    display: flex;
    // gap: 1rem;
    // overflow: hidden;
    overflow-x: scroll;
    justify-content:center;
    align-items: center;
    width: 100%;

    // -webkit-mask-image: linear-gradient(90deg, transparent, #fff 7%, #fff 93%, transparent);
    // mask-image: linear-gradient(90deg, transparent, #fff 7%, #fff 93%, transparent);
    // overflow: unset;
    // position: relative;
    // transform-style: preserve-3d;
    // backface-visibility: hidden;
    // will-change: transform, transition;
  }
  .category {
    
    // animation: animate 20s linear infinite;
    // animation-direction: alternate;
    animation-delay: 10s;

    width: 280px;
    height: 350px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
    overflow: hidden;
    padding: 1rem;
    background-color: #fff;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    position: relative;
    margin-right: 1rem;
    transition: 500ms ease 0s;


  @keyframes animate {
    0%{
      transform: translateX(100%);
    // transform: translate3d(-1336.5px, 0px, 0px);
    }
    100%{
      transform: translateX(-100%);
    }
  }
    img {
      width: 100%;
      height: 90%;
      cursor: pointer;
      object-fit: cover;
      margin-top: 1rem;
    }
    .anchor{
    position: absolute;
    bottom: 1%;
    background: transparent;
    color: var(--fblack);
    border-bottom: 2px solid var(--gray);
    opacity: .7;
    background: #fff;
border-top-right-radius:3em  ;
border-bottom-right-radius:3em  ;
min-height: 40px;

    }
    .anchor:hover{
      border-bottom: 2px solid var(--mag);
      transition: ease .6s;
      opacity: 1;
      border-radius: 0;
    }
  }
  @media screen and (max-width: 1080px) {
    .categories{
     justify-content: flex-start;
    //  flex-wrap: wrap;
    }
    .category{
      width: 17rem;
      height: 20rem;

      img{margin-top: 0;}
    }
  }
  @media screen and (max-width: 800px) {
    .categories{
      justify-content: center;
      flex-wrap: wrap;
      overflow: hidden;
     }
    .anchor{
      display: none;
    }
    .category{
      width: 150px;
      height: 150px;
      border-radius: 50%;
      text-align: center;
      margin-bottom: 20px;
h3{
  position: absolute;
  bottom: 0px;
  left: 0%;
  z-index: 2;
  background: #fff;
  opacity: .7;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  padding: 2px;
  font-weight: 500;
}
      img{
        border-radius: 100%;
        position: absolute;
        top: 0;
        left: 0;
        height: inherit;
        z-index: 1;
      }
    }
  }

