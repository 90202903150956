.news-letter{
  display: flex;
  flex-direction: column;
  margin-right: 20px;
}
small{
  color: var(--grey);
  font-weight: 300;
}
.news-mail{
height: 42px;
display: flex;
align-items: center;
border-radius: .3em;
overflow: hidden;

input[type="email"]{
height: inherit;
width: 80%;
border: 1px solid var(--gray);
outline: none;
border: none;
padding-left: 12px;
color: var(--fblack);
font-size: 14px;
border-radius: 0 !important;
}
button{
  width: 20%;
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
  background: var(--m);
  color: #fff;


}
}

@media screen and (max-width:420px) {
  // .news-mail{
  //   min-width: 95vw !important;
  //   max-width: 99vw !important;

  // }
}



.footer-link{
  display: flex;
  min-height: 60px;
  align-items: center;
  justify-content: space-between;
  // background-color: #eee;
  background: blue;
  padding: 1rem;

}
.f-links{
  ul{
    list-style-type: none;
  }
  li,a{
    color: var(--grey);
    font-weight: 500;
    font-size: 14px;

    &:hover{
      color: var(--m);
      transition: .3s ease;
    }
  }
}
.f-icons{
  &>*{margin-right: 10px;}
  :hover{
    transition: transform 0.4s ease;
    transform: rotate(360deg);
    }
}

@media screen and (max-width: 600px) {
  .f-links>ul{
    align-items: flex-start;
    flex-direction: column;
  }
}

// Start
.btn {
  display: inline-block;
  padding: .5rem ;
  color: var(--white);
  border: 1px solid var(--m);
  cursor: pointer;
  position: relative;
  z-index: 1;
  overflow: hidden;
  text-decoration: none;
  border-radius: .3em;
}

.btn-dark {
  background-color: transparent;
  overflow: hidden;
  color: var(--white);
}

.btn::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 99.5%;
  height: 100%;
  background-color: var(--mag);
  color: var(--white);
  z-index: -1;
  transform: translateX(-100%);
  transition: all 0.4s;
}

.btn:hover::before {
  transform: translateX(0);
  color: var(--white);
}
// ends...
// Footer section
.footer-section {
  padding: 2rem 0;
  background-color: var(--fblack);
}

.footer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  row-gap: 5rem;
  padding: 0 1rem 0 1rem;
}

.link-heading {
  color: #eee;
  position: relative;
  margin-bottom: 1rem;
}

.link-heading:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 25px;
  height: 2px;
  background-color: #eee;
}

.footer-links {
  display: flex;
  flex-direction: column;
  text-decoration: none;
  list-style: none;
  align-items: flex-start;
  justify-content: flex-start !important;

}

.footer-links li {
  padding-bottom: 1rem;
  text-decoration: none;
  

  a {
    color: #ddd;
    font-size: 14px;
  }
}

// Media Query 600px
@media screen and (max-width: 600px) {
  .contact {
    flex-direction: column;
  }

  .contact > * {
    margin: 3rem 0;
  }
}