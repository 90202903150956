.coupon-form {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 40px;
  border: 1px solid var(--gray);
  font-size: 14px;
  padding-left: 12px;
  overflow: hidden;
  input {
    height: 100%;
    width: 80%;
    max-width: 80%;
    border: none;
    outline: none;
  }
  button {
    height: 40px;
    width: 20%;
    background: var(--m);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #f7f7f7;
    margin-top: 0 !important;
outline: none;
border: none;
  }
}

.coupon-msg {
  border: 1px solid var(--mag);
  padding: 1rem;
  margin-bottom: 3px;
}
