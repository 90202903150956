.f-width{
min-width: calc(100vw - 24px) !important;
max-width: 100vw !important;
}
.fit-width{
width: 85vw;
}
.product {
    display: flex;
    padding:1rem .7rem 1rem .7rem ;
    width: 100vw ;

    .filterr {
      padding: .5rem;
      border-right: 1px solid var(--gray);
      margin-right: 10px;
      width: 15vw;
    }
    .pcontent{
    //  width: calc(100vw - 15vw);
    }

  }
  @media screen and (max-width:1125px) {
    .filterr {
      width: 20vw !important;
    }
    .pcontent{
      width: 80vw;
    }
  }
  @media screen and (max-width:1024px) {
    .filterr,.closemodl{
      // display: none;
    }
    .pcontent{
      width: 100vw !important;
    }
.filterr{
  position: fixed;
  width: 30vw !important;
  min-height: 100vh;
  max-height:100vh;
  top: 4.3rem !important;
  left: 0;
overflow-y: scroll;
background: white;
z-index: 90;
display: none !important;
}
.closemodl{
  content: "";
  position:fixed;
  z-index: 200;
  right: 0;
  top: 4.3rem;
  width: 70vw;
  height: 100%;
  background: rgba(0, 0,0, 0.2);
  display: none;
}
  }
  @media screen and (max-width:750px) {
    .filterr{
     width: 40vw !important;
    }
    .closemodl{
      width: 60vw !important;
    }
  }
  @media screen and (max-width:550px) {
    .filterr{
     width: 50vw !important;
    }
    .closemodl{
      width: 50vw !important;
    }
  }
  //Larger Screen set items to 5
  @media screen and (min-width: 1441px) {
    .filterr {
      width: 15vw !important;
    }
    .pcontent{
      width: 85vw!important;
    }
  }