.create-category{


    h3{
        // font-family: var(--font-family2);
        // color: var(--fblack);
        margin-bottom: .5rem;
    }
    p{
        margin-bottom: 1rem;
    }
    form{
        display: flex;
        flex-direction: column;

        label{
            margin-bottom: 1rem;
        }
        input[type="text"]{
         height: 40px;
         padding: 8px;
         margin-bottom: 1rem;
        }
    }
}

.category-list{
background: #fff;
h3{

}
.table{
    padding: 5px;
    width: 100%;
    overflow-x: auto;
}
table{
 border-collapse: collapse;
 width: 100%;
 font-size: 1rem;
}
thead{
border-top: 2px solid var(--mag);
border-bottom: 2px solid var(--mag);

}
th{
    border:1px solid #eee
}
th,
td{
vertical-align: top;
text-align: left;
padding: 8px;
}
tr{
    border:1px solid #ccc;
}
}