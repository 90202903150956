@keyframes slide-up {
    0% {
      visibility: visible;
      top:3rem;
    }
    100% {
      visibility: visible;
      top:0em;
    }
  }
  @keyframes slide-left {
    0% {
      visibility: visible;
      left:3rem;
    }
    100% {
      visibility: visible;
     left:0em;
    }
  }
  @keyframes slide-right {
    0% {
      visibility: visible;
      /* right:100%; */
      right:5%;
    }
    100% {
      visibility: visible;
     right:0%;
    }
  }
.slider{
position: absolute;
top:0;
left: 0;
width: 100%;
height: 100vh;
overflow: hidden;
z-index: 99;
background: transparent;
background-attachment: fixed;

.slide {
position: absolute;
opacity: 0;
transition: all 0.5s ease-in-out;
width: 100%;

}

.current {
opacity: 1;
}
img{
 width: 100%;
 height: 100vh;
 border: 0;
 object-fit: cover;
 transition: all .5s ease;
 z-index: 99;
}
.content{
 position: absolute;
 z-index: 99;
 left:0;
 /* top:7rem; */
 top:0em;
 display: flex;
 flex-direction: column;
 gap: 1rem;
 align-items: center;
 justify-content: center;
 width: 100%;
 height: 100%;



/* animation: slide-up 1s ease 0.5s;
animation-fill-mode: forwards; */
overflow: hidden;


}

.top-content{
display: flex;
flex-direction: row;
align-items: flex-end;
width: 100%;
height: 65%;
padding-left: 2rem;
padding-right: 2rem;


}

  
.left-child{
color:var(--mag);
display: flex;
flex-direction: column;
flex: 1;
padding: 20px;
gap:1rem;
animation: slide-up 1s ease 0.5s;
animation-fill-mode: forwards;
}
.left-child > h3{
text-transform: uppercase;
color: #f7f7f7;
font-weight: 500;
}
.left-child > h1{
text-transform: capitalize;
font-size: 2.5rem;
font-weight: 900;
margin: 0;
}
.left-child > p{
text-transform: capitalize;
font-size: 12px;
font-weight: 400;
color: var(--white);
line-height: 20px;
}
.shop-btn{
background: var(--mag);
width: 100px;
height: 40px;
color:var(--white);
display: flex;
align-items: center;
font-size: 14px;
padding-left: 12px;
animation: showbtn 1s ease-in-out 0.6s 1 forwards;
}
@keyframes showbtn {
    from{
        opacity: 0;
        transform: translate(0,100px);
        filter:blur(33px);
    }
    to{
        opacity: 1;
        transform: translate(0,0);
        filter:blur(0);
    }
}
.right-child{
display: flex;
flex: 1;
align-items: flex-start;
justify-content: flex-end;
height: 70%;
gap:.3em;

animation: slide-left 1s ease 0.5s;
animation-fill-mode: forwards;
}
.button{
    width: 40px;
    height: 40px;
    border:1px solid var(--white);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white);
  
    }
    .button:hover{
     border: 1px solid var(--white);
     transition: .6s all;
     color: var(--fblack);
     background: var(--white);
    }
.bottom-content{
    display: flex;
    flex-direction: row;
    /* width: 75%; */
    width: 95%;
    height: 35%;
    padding: 2%;
    justify-content: center;
    animation: slide-right 1s ease 0.5s;
animation-fill-mode: forwards;
    } 

.thumbnail{
display: flex;
width: 100%;
gap:1rem;
padding: 12px;
overflow-x: scroll;
align-items: center;

}
.image-card{
    min-width: 200px;
    max-width: 200px;
    height: 200px;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 
     var(--box-shadow),
     var(--box-shadow);
        } 
.thumbnail  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.active-thumb{
border:5px solid var(--white);
}
}
@media screen and (max-width: 800px) {
    .thumbnail{
        align-items: center
    }
    .image-card{
        min-width: 150px !important;
        max-width: 150px !important;
        height: 150px !important;
    }
    .content{
        gap: .1rem !important;
    }
    .left-child{
     gap: .3rem !important;
    }
    .left-child > h1{
     font-size: 2rem !important;
    }
    .left-child > h3{
        font-size: 14px !important;
       }
       .shop-btn{
        margin-top: 10px;
       }
}
@media screen and (max-width: 520px) {
    .left-child > h1{
        font-size: 1.5rem !important;
    }
}
@media screen and (max-width: 390px) {
    .left-child > h3{
        /* display: none; */
    }
    .left-child > h1{
        font-size: 1.1rem !important;
    }
    .left-child > p{
        font-size: 11px !important;
        line-height: 16px !important;
    }
}


 .span1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    box-sizing: border-box;
  }
  
 .span1:before {
    content: "";
    position: absolute;
    top: 0px;
    left: 0%;
    width: 30%;
    height: 2px;
    background: #50dfdb;
  
    animation: span1 3s linear infinite;
  }

  
  @keyframes span1 {
    0% {
      left: 0%;
    }
    100% {
      left: 100%;
    }
  }

  