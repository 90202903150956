.review{
font-size: 14px;
padding-right: 12px;
}
form {

  label {
    display: block;
    font-weight: 500;
    margin-bottom: 4px;
  }



  textarea{
width: 100%;
padding: 6px;
outline: none;
  }
}
