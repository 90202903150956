.loader-wrapper{
    position: fixed;
    width: 100%;
    height: 100%;
    background:#fff;
    z-index: 105 !important;
}
.loader{
position: fixed;
left: 50%;
top:50%;
transform: translate(-50%, -50%);
z-index: 102 !important;
}
.loaderImg{
max-width: 350px;
}
.loader-text{
display: block;
text-align: center;
width: 100%;
color: var(--grey);
font-weight: 300;
margin-top: -35px;
}