.user-summary{
    width: 100%;

    .info-summary{
     display: flex;
    //  flex-wrap: wrap;
    margin-top: 10px;
    overflow-x: scroll;
    }
}
.card{
 padding: 5px;
}
.card1,.card2,.card5,.card3,.card4{
 border:1px solid var(--gray);
}

@media screen and (max-width: 100) {
    
}