.send-section{
background:#f7f7f7;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
}
.send-wrapper{
background: #fff;
width: 400px;
margin: 20px;
padding: 20px;
display: flex;
flex-direction: column;
align-items: flex-start;
}
.send-header{
display: flex;
width: 100%;
margin-bottom: 20px;
font-size: 14px;
}
.send-header span:first-child{
display: flex;
flex: 1;
align-items: center;
justify-content: flex-start;
}
.send-header span:last-child{
display: flex;
flex: 1;
align-items: center;
justify-content: flex-end;
color: var(--mag);
cursor: pointer;
 }
 .send-header span:last-child:hover{
text-decoration: underline;
transition: .6s ease;
 }
 .send-form{
width: 100%;
color: var(--gray);
 }
 .send-form label{
font-size: 14px;
margin-bottom: 6px;
color: var(--grey);
 }
 .send-form >div{
margin-bottom: 20px;
 }
 .mail-check{
display: flex;
height: 47px;
 }
.mail-check input[type="email"]{
width: 80%;
padding-left: 8px;
margin-right: 6px;
border: 1px solid var(--gray) !important;
border-radius: .3em;
color: var(--grey);
font-size: 14px;
}
.mail-check button{
width: 20%;
background: var(--black);
color: #f7f7f7;
border: none;
outline: none;
}
.receiver-name{
margin-top: -16px;
font-size: 12px;
color: var(--grey);
font-weight: 600;
}
.currency{
display: flex;
align-items: center;
border:1px solid var(--gray);
border-radius: .3em;
overflow: hidden;
}
.currency span:first-child{
    width: 50px;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f7f7f7;
    color: var(--fblack);
}
.currency-input{
width: 100%;
height: 47px;
border-radius: 0 !important;
border: none;
outline: none;
background: #fff;
padding-left: 8px;
font-size: 14px;
color: var(--grey);
border-left: 1px solid var(--gray);
border-right: 1px solid var(--gray);

}
.flag{
height: 15px;
display:flex;
overflow: hidden !important;
margin-left: 6px;
margin-right: 6px;
}
.flag-span{
width: 10px !important;
height: 20px !important;
}
.flag-span:nth-child(1){
background: green !important;
}
.flag-span:nth-child(2){
background: white;
}
.flag-span:nth-child(3){
background: green;
}
.cur-name{
font-size: 12px;
margin-right: 6px;
// color: var(--grey);
}
.send-input{
height:40px;
width: 100%;
border: 1px solid var(--gray) !important;
padding-left: 6px;
color: var(--grey);
border-radius: .3em;
}
.textarea{
width: 100%;
height: 47px;
border: 1px solid var(--gray) !important;
padding-left: 8px;
border-radius: .3em;
color: var(--grey);
font-size: 14px;
}
.send-btn{
width: 100%;
height: 40px;
border: none;
outline: none;
background: var(--mag);
color: #f7f7f7;
}