.footer{
  max-width: 100%;
  background: #ddd;
  background-color: var(--fblack);
  color: var(--white);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  padding: 1em 1rem  0 1rem;
  font-size: 12px;
  border-top:1px solid #aaa
  
  }

 a{
  color: #ddd ;
  cursor: pointer;
 &:hover{
  opacity: .8;
  transition: .3s ease-in;
 }
}

@media screen and (max-width:1024px) {
    .footer{
    padding-bottom:  4rem ;
    }
  }
