.product-list {
    .srch-cont{
      height: 40px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 8px;

      input[type="search"]{
        height: 100%;
        border-radius: .3em;
        width: 400px;
        border: 1px solid var(--gray);
        outline: none;
        font-size: 14px;
        padding-left: 12px;
        background: #ffffff;
      }
    }
    .pl-top {
      width: 100%;
      height: 40px;
      padding-bottom: .5rem;
      border-bottom: 1px solid var(--gray);
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      // flex-wrap: wrap;
      margin-bottom: 8px;
      .pl-icons {
        & > * {
          cursor: pointer;
        }
      }
  
      .pl-sort {
        height:100%;

        select {
          font-size: 14px;
          height: 35px;
          padding: 8px;
          border: 1px solid transparent;
          background: #f7f7f7;
          outline: none;
          border-radius: .3em;
        }
      }
    }

  }
  
  .grid {
    display: flex;
    flex-wrap: wrap;
    // gap:.7rem

    }
  @media screen and (max-width:464px) {
    .srch-cont{
      input[type="search"]{
        width: 280px !important;
      }
    }
  }
  @media screen and (max-width:420px) {
    .srch-cont{
      input[type="search"]{
        width: 250px !important;
      }
    }
  }
  @media screen and (max-width:390px) {
    .srch-cont{
      input[type="search"]{
        width: 220px !important;
      }
    }
  }
  @media screen and (max-width:360px) {
    .srch-cont{
      input[type="search"]{
        width: 200px !important;
      }
    }
  }
  
  // @media screen and (max-width:1024px) {
    .hidelist{
      display: none !important;
    }
  // }