.container{
  min-height: 300px;
  // I provide the height because its causinf rerendering for the wallet
}.wallet {
  font-size: 14px;
  .wallet-data {
    width: 100%;
    margin-bottom: 2rem;
    display: flex;
    justify-content: space-between;
    height: 100%;
  }
  .mr{
    margin-right: 30px;
  }
  .wallet-info {
    width: 50%;
    padding: 1rem;
    border-radius: 0;
    background:#ffffff;

    .account-info{
      display: flex;
      flex-direction: column;
      div{
        height: 40px;
        margin-top: 10px;
        border-bottom: 1.5px solid var(--gray);
        display: flex;
        justify-content: space-between;
        align-items: center;
        span:first-child{
          color: var(--grey);
          font-weight: 600;
          font-size: 13px;
        }
        span:last-child{
          color: var(--grey);
        }
        .account-active{
          color: green !important;
          font-weight: normal !important;
        }
        .account-inactive{
          color: tomato !important;
          font-weight: normal !important;
        }
      }
    }
    h4{
      font-weight: 600;
      color: var(--fblack);
      margin-bottom: 10px;
      font-size: 16px;

    }
    .buttons{
      height: 40px;
      align-items: center;
      .--btn{
        height: 40px;
        margin-right: 10px;
        font-size: 16px;
      }
    }
    .notice{
      margin: 12px;
      font-size: normal;
      color:var(--grey);
      font-size: 12px;
      font-weight: 600;
    }
  }
}
@media screen and (max-width:800px) {
  .wallet-data{
    flex-direction: column;
  }
  .wallet-info{
    width: 100% !important;
  }
  .mr{
    margin-bottom: 30px;
  }
}