.form{
width: 100%;
display: flex;
margin: 0 auto;
background: var(--lightgray);
min-height: 100vh;
align-items: center;
justify-content: center;

.form-wrapper{
display: flex;
flex:1;
flex-direction: column;
align-items: center;
justify-content: center;
padding: 20px;
cursor: pointer;
position: relative;
box-shadow: var(--box-shadow);
background: var(--white);
// border-radius: .3em;
max-width: 400px;
border-top: 4px solid var(--m);

h4,small{
color: #444;
margin: 10px 0 10px 0;
font-weight: 600;
}
}
form{
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
padding: 8px;
gap:1rem;
margin: 0px 20px 20px 20px;
}
.input-cont{
width: 100%;
display: flex;
gap:.6em;
padding: 8px;
height: 50px;
width: 100%;
align-items: center;
border: 1.5px solid var(--gray);
border-radius: .3em;

input,.phone-input{
width: 100%;
// height: 35px;
height: 50px;
border:none;
outline: none;
background: none;
appearance: none;
color: var(--grey);
font-size: 14px;
font-weight: 400;
// background: #000;

&:focus{
    border-bottom: 2px solid green;
    border-radius: 0 !important;
}
&:focus:invalid{
    border-bottom: 2px solid tomato;
    border-radius: 0 !important;
}
}


}
input[type=submit]{
    background: var(--mag);
    appearance: none;
    border: none;
    outline: none;
    width: 50%;
    height: 40px;
    color: var(--white);
    font-weight: 600;
    font-size: 14px;
}
    input[type=submit]:hover{
     transition: transform 0.4s ease;
     transform: scale(1.1);
     
    }

    .remember-section{
     width: 100%;
     height: 30px;
     display: flex;
     justify-content: space-between;
     align-items: center;
     
     label,span{
     font-size: 12px;
     display: flex;
     align-items: center;
     gap:.2em;
     color: var(--black);
     }
     :hover{
        text-decoration: underline;
        transition: 0.6s ease-in-out;
     }
    }
    .warning-msg{
        font-size: 12px;
        color:var(--grey);
        }
    .new-user{
     font-size: 12px;
     
    }
    .sign-up{
     font-weight: 600;
     color: var(--mag);


    }
    .sign-up:hover{
        text-decoration: underline;
        transition: 0.4s ease-in-out;
     }
     .powered-by{
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 0;
        height: 30px;
        gap:.7em;
        margin-bottom: -40px;
        
        span{
            font-weight: 600;
            font-size: 12px;
            color: var(--grey);
        }
        img{
            width: 25px;
            height: 25px;
        }
     }

}

.no-uline{
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    
    label,span{
    font-size: 12px;
    display: flex;
    align-items: center;
    gap:.2em;
    color: var(--black);
    text-decoration: none;
    }
    :hover{
       text-decoration: none !important;
    }
   }
   .group{
    padding: 5px;
    box-shadow: var(--box-shadow);
    align-self: flex-start;
    width: 100%;
    
    ul{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
   }
   .indicator{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 10px;
    color:var(--grey);
    font-weight: bold;
   }
   .none{display: none !important;}
   @media screen and (max-width:450px) {
    .form-wrapper{
        border-top: none !important;
    }
   }
