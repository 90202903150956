.add-product {
  margin-bottom: 5rem;
  background: white;
  .card {
    width: 100%;
    // max-width: 500px;
    padding: 1rem;
  }
  form {
    label {
      display: block;
      font-size: 1.4rem;
      font-weight: 500;
      color: var(--color-dark);
    }
    select{
      text-transform: capitalize;
    }
    input[type="text"],
    input[type="number"],
    input[type="file"],
    input[type="email"],
    select,
    input[type="password"] {
      display: block;
      font-size: 14px;
      font-weight: 400;
      padding: 1rem;
      margin: 1rem auto;
      width: 100%;
      border: 1px solid var(--gray);
      border-radius: 3px;
      outline: none;
      min-height: 47px;
    }
  }
}

.group {
  border: 1px solid var(--mag);
  padding: 5px;
}

.image-preview {
  width: 100%;
  height: 100%;
  max-height: 280px;
  background: var(--mag);
  padding: 1rem;
  border-radius: 3px;
  overflow: hidden;
  // display: none;
  transition: all 0.3s;
}

.image-preview img {
  width: 100%;
}

.image-show {
  display: block;
}
// Upload Widget styles
.uploadWidget{
  // max-width: 500px;
  border:2px solid var(--fblack);
  margin-top: 10px;
  border-radius: .3em;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 70px;
  flex-direction: column;
  input{display: none;}
  // input{visibility: hidden;}
}
.images {
  border: 1px solid transparent;
  border-radius: 5px;
  box-shadow: var(--box-shadow);
  overflow: hidden;
  background-color: #333;
  margin-top: 1rem;
  color: #fff;
  // min-width: 200px;
  // max-width: 500px;
  display: flex;
  overflow-x: scroll;
}

.image {
  margin: 1rem 0.5rem;
  position: relative;
  border-bottom: 1px solid #fff;
  padding-bottom: 5px;
  & p {
    color: #fff;
  }
}

.image button {
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: pointer;
  border: none;
  color: white;
  background-color: transparent;
  border: 1px solid #fff;
  margin-bottom: 3px;
  padding: 1px;
}

.image button:hover {
  background-color: red;
}

.image p {
  padding: 0 0.5rem;
  margin: 0;
}

.upload-btn {
  cursor: pointer;
  display: block;
  margin: 0 auto;
  border: none;
  border-radius: 20px;
  width: 10rem;
  height: 3rem;
  color: white;
  background-color: green;
}

// label:hover,
// .upload-btn:hover {
//   box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
//     rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
// }

.error {
  text-align: center;
}

.error span {
  color: red;
}

.progress {
  background-color: #aaa;
  border: 1px solid transparent;
  border-radius: 10px;
  width: 100%;
  .progress-bar {
    background-color: var(--lmag);
    border: 1px solid transparent;
    border-radius: 10px;
    color: #fff;
    font-size: 1.2rem;
    font-weight: 500;
    padding: 0 1rem;
    text-align: center;
  }
}

// Product Preview
.slide-container {
  max-width: 1000px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

aside {
  width: 100%;
  /* height: 300px; */
  margin: 0 auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  border: 1px solid black;
  transition: all 0.3s;
}

aside::-webkit-scrollbar {
  height: 10px;
  width: 2px;
  border: 1px solid #d5d5d5;
  background: #d5d5d5;
}

aside::-webkit-scrollbar-track {
  --webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

aside::-webkit-scrollbar-thumb {
  background-color: var(--mag);
  outline: 1px solid #eee;
  /* border-radius: 100px; */
}

aside .thumbnail {
  flex: 0 0 auto;
  object-fit: cover;
  margin: 5px;
  border: 1px solid #333;
  overflow: hidden;
  transform: scale(0.95);
  transition: all 0.3s;
}

aside .thumbnail:hover {
  transform: scale(1);
}
aside .thumbnailIcon {
  cursor: pointer;
  color: red;
}
