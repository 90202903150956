.aside{
    height: 65px;
    width: 100%;
    position: fixed;
    bottom: 0;
    z-index: 100;
    background-color: #fff;
    box-shadow: var(--box-shadow);
    /* padding:0%; */
    display:none;
    }
    .aside>ul{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap:0.5rem;
    height: 100%;
    font-size: 14px;


    }
    .b-links{
     display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: center;
     font-size: 12px;
     color: var(--fblack);
     &:hover{
    color: var(--mag);
    transition: .3s ease;
     }
    }
     .active {
        color: var(--mag);
        }
    /*-- Responsive section--*/
    /* @media screen and (max-width:660px) {*/
    @media screen and (max-width:1024px) {
        .aside{display:block}
        }