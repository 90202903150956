// Scope for product grid: i dont assign m query .img at first i let the grid auto height work and i then inspect the img per query to assign a height

.grid {
  height: 400px;   
width: calc(21.3vw - 15px);
    margin-bottom:10px;
    margin-right: 7px;
    background: #fff;
    box-shadow: var(--box-shadow);
    border-radius: .3em;
    overflow: hidden;

    .img {
      min-width: 100%;
      max-width: 100%;
      min-height: 70%;
      max-height: 70% ;

    }
  }
  
  // Grid Query
  @media screen and (max-width: 464px) {
   .price-percent{display: none !important;}
  }
  @media screen and (max-width:1125px) {
    .grid{
    // width: 26.5vw;
    width: calc(26.6vw - 16px);
    height: auto;
    .img {
      // min-height: 70%;
      // max-height: 70%;
    height: 300px;

    }
    h4{
      font-size: 12px;
      line-height: 12px;
    }
    .rview{
      margin-top: 4px;
    }
    del{
      font-size: 12px;
    }
  }
  }
  @media screen and (max-width:1024px) {
    .grid{
      // width: 31.7vw;
      width: calc(33.3vw - 15px);
      .img{
        height: 325px;
      }
    }
  }
  @media screen and (max-width:890px) {
    .grid{
      // width:31vw;
      // width: calc();
      .img{
        height: 275px;
      }
    }
  }
  @media screen and (max-width:800px) {
    .grid{
      .img{
        height: 250px !important;
      }
    }
  }
  @media screen and (max-width:700px) {
    .grid{
      // width: 46vw;
      .img{
        // height: 320px !important;
        height: 230px !important;
      }
    }
  }
  @media screen and (max-width:550px) {
    .grid{
      .img{
        height: 200px !important;
      }
    }
  }
  @media screen and (max-width:500px) {
    .grid{
      width: calc(50vw - 19px);
    }

  }
  @media screen and (max-width:370px){
  .percentage-discount{
    display: none;
  }}
  @media screen and (max-width:299px) {
    .grid{
      width: calc(100vw - 20px);
      .img{
        height: 330px !important;
      }
    }
  }
  //Ends....



  .list {
    width: 100%;
    height: 200px;
    display: flex;
    margin: .7rem 0;
    position: relative;
    box-shadow: var(--box-shadow);
    border-radius: .3em;
    overflow: hidden;
    color: var(--grey);

    &:first-of-type{margin-top: 0!important;}

    .img {
      width: 200px;
      height: 100%;
      overflow: hidden;
      img {
        width: 100%;
        max-height: 100%;
        cursor: pointer;
      }
    }
  
      .details {
        margin-left: 20px;
        display: flex;
        flex-direction: column;
        width: 100%;
        h4 {
          font-weight: 400;
          color: var(--fblack);
          font-size: 14px;

        }
      }
    }
  // List M Query

  @media screen and (max-width: 502px) {

    .list{
      height: 150px;
      .img{width: 150px;}
    }
        .list-desc{
      display: none;
    }
  }
  //Ends....

//Larger Screen set items to 5
@media screen and (min-width: 1441px) {
.grid{
  width: calc(17vw - 16px ) !important;
}
}

@media screen and (min-width: 2250px) {
  .grid{
    width: calc(17vw - 16px ) !important;
height: 550px !important;
    // background: yellow !important;
    .img {
      min-width: 100% !important;
      max-width: 100% !important;
      min-height: 70% !important;
      max-height: 70% !important ;

    }
  }
  }

