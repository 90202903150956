.table {
    width: 100%;
    // overflow-x: auto;
  
    table {
      border-collapse: collapse;
      width: 100%;
      th{
        // font-weight: 600;
      }
      
      .pending {
        color: var(--grey);
        font-weight: 500;
        text-transform: capitalize;
        
      }
      .delivered {
        color: green;
        font-weight: 500;
        text-transform: capitalize;
      }
    }
  }


  @media screen and (max-width:1000px) {
    .hideforscreen{
       display: none;
    }
    .screen-wrapper{display: flex;flex-direction: column;}
    // Screen Design
   .screen-cont{
       border: 1px solid var(--gray);
       display: flex;
       justify-content: space-around;
       align-items: center;
       padding: 8px;
       border-radius: .3em;
       margin-bottom: 20px;
       height: 60px;
       color: var(--fblack);
       }
       .mobile-trans{
           width: 70%;
   
           span:first-child{
            margin-bottom: 4px;
            font-weight: 500;
            text-transform: uppercase;
           }
           span:last-of-type{
           font-size: 12px;
           }
       }
   
       .mobile-transac{
           // width: 70%;
           align-items: flex-end;
   
           span:first-child{
            margin-bottom: 4px;
           }
           span:last-of-type{
           font-size: 12px;
           text-transform: capitalize;
           }
       }
   }
   .grey{
    color: var(--grey) !important;
   }
   .green{
    color: green!important;
   }