
.profile-wrapper{
min-width: 100vw;
max-width: 100vw;
display: flex;

}

.nav-tab{
width: 300px;
min-height: 90vh;
border-right: 1px solid var(--gray);
background: white;
transition:  width 3s ease;
// overflow: hidden;
// transform: margin-left .5s;
}
 
.profile-content{
width: 100%;
// padding: 14px;
box-sizing: border-box;
margin: 10px;
background: white;
}
@media  screen and (max-width:1000px) {
.nav-tab{
    // background: #000;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 200;
    height: 100vh;
}
.close-bg{
position: fixed;
top: 0;
right: 0;
background: rgba(0,0,0,0.8);
width: calc(100vw - 300px);
height: 100vh;
z-index: 101;
}
}