.back-to-order, .back-to-order>a{
color: var(--mag);
}
.order-content >b{
margin-right: 6px; 
color: var(--fblack);
font-weight: normal !important;

}
.order-content{
margin-bottom: 6px;
font-size: 14px;
color: var(--grey);
text-transform: capitalize;
}
table{width: 100%;}