.change-password{
    // padding: 12px;
}
.input-cont{
    width: 100%;
    display: flex;
    gap:.6em;
    padding: 8px;
    min-height: 40px;
    width: 100%;
    align-items: center;
    border: 1.5px solid var(--gray);
    border-radius: .3em;
    margin-bottom: 10px;
    
    input,.phone-input{
    width: 100%;
    height: 35px;
    border:none;
    outline: none;
    background: none;
    appearance: none;
    color: var(--grey);
    font-size: 14px;
    font-weight: 400
    // background: #000;
    
    
}}