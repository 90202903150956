.charts {
    width: 100%;
    margin-left: 20px;
    max-width: calc(50vw - 40px);
}
    .card {
      padding: 1rem;
      border-bottom: 3px solid tomato;
    }
  @media screen and (max-width:1000px) {
    .charts{
      max-width: calc(100vw - 20px) !important;
      margin-left: 0 !important;
    }
  }
  
  