.product-list {
  // background: #fff;
    color: #333;
    .table {
      // padding: 5px;
      width: 100%;
      overflow-x: auto;
  
      .search {
        width: 100%;
        max-width: 300px;
      }
  
      table {
        border-collapse: collapse;
        width: 100%;
        font-size: 1rem;
  
        // thead {
        //   border-top: 2px solid var(--lmag);
        //   border-bottom: 2px solid var(--lmag);
        // }
  
        // th {
        //   border: 1px solid #eee;
        // }
  
        th,
        td {
          vertical-align: top;
          text-align: left;
          padding: 8px;
  
          &.icons {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            > * {
              margin-right: 7px;
              cursor: pointer;
              vertical-align: middle;
              align-self: center;
            }
          }
        }
  
        tr {
          border-bottom: 1px solid #ccc;
        }

        tbody {
          tr:hover {
            cursor: pointer;
            background-color: rgba(31, 147, 255, 0.3);
          }
        }
      }
    }
  }

@media screen and (max-width: 1000px) {
    .hideforscreen{
       display: none;
    }

  // Mobile View
  .admin-products{
  border: 1px solid teal;
  border-radius: .5em;
  padding: 8px;
  min-height: 40px;
  margin-bottom: 10px;
  // font-size: 14px;
  }
  .--color-green{
  color: teal;
  }
  .--color-danger{
  color: tomato;
  }

}