.dashboard{
    width: 100%;
    min-height: 80vh;
    box-shadow: var(--box-shadow);
    background: #fff;
    border-radius: .7em;
    padding: 12px;

    .dtitle{
    //  font-weight: bold;
     font-size: large;
     color: var(--fblack);
    }
    form{
    // border: 2px solid red;
    .d-photo-wrap{
        background: #f7f7f7;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-height: 100px;
        margin-bottom: 10px;
        margin-top: 10px;
        padding: 12px;
        position: relative;
        .imgcont{
            // background: var(--black);
            width: 120px;
            height: 120px;
            border-radius: 50%;
            overflow: hidden;
            img{
                max-width: 100%;
                height: inherit;
                object-fit: cover;
                // &:hover{
                //     width: 200px;
                //     position: absolute;
                // }
            }
        }
    }
    .dashwrapper{
        // padding: 12px;
        display: flex;
        flex-direction: row;
    }
    .l-form{
    flex:1;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    padding: 12px;
    // box-shadow: var(--box-shadow);
    border-radius: .7em;
    margin-right: 20px;
    // border:1px solid #ddd;
    .lcontent{
        min-height: 47px;
        display: flex;
        // align-items: center;
        justify-content: flex-start;
        margin-bottom: 10px;
        label{
            max-width: 100px;
            min-width: 100px;
            color: var(--grey);
            // font-weight: bold;
            font-size: 14px;
        }
        input,input[type="date"]{
            height: 47px;
            margin-left: 10px;
            width: 100% !important;
            appearance: none;
            border:1px solid #ddd;
            border-radius: .3em;
            padding: 6px;
            padding-left: 12px;
            font-size: 14px;
            &:focus{
                border: 1px solid #ddd;
                outline: 1px solid#ddd;

             }
        }
    }
    }
    .r-form{
     flex: 1;
     flex-direction: column;
     margin-bottom: 20px;
     padding: 12px;
    //  box-shadow: var(--box-shadow);
     border-radius: .7em;
    //  border:1px solid #ddd;
     .rcontent{
         min-height: 47px;
         display: flex;
        //  align-items: center;
         justify-content: left;
         margin-bottom: 10px;
         label{
             max-width: 100px;
             min-width: 100px;
             color: var(--grey);
            //  font-weight: bold;
             font-size: 14px;
         }
         input{
             height: 47px;
             margin-left: 10px;
             width: 100%;
             border:1px solid #ddd;
             border-radius: .3em;
             padding: 6px;
             padding-left: 12px;
             text-transform: capitalize;
             &:focus{
                border: 1px solid #ddd;
                outline: 1px solid#ddd;

             }
         }
        }
    }
    input[type="submit"],input[type="button"]{
        margin: 20px 0 20px 0;
        padding: 12px;
        font-size: 14px;
        font-weight:500;
        border-radius: .3em;
        outline: none;
        border: none;
        background: var(--fblack);
        color: #fff;
        transition: all .4s;
        &:hover{
transform: translateY(-2px);
        }
    }
    }
}
.upload-icon{
display: flex;
align-items: center;
justify-content: center;
color: var(--fblack);
margin-bottom: 4px;
opacity: 1;

&:hover{
opacity: .8;
color: var(--black);
transition: .3s ease-in-out;
}
}
@media screen and (max-width:1000px) {
 .dashwrapper{
 flex-direction: column !important;
 }
.l-form,.r-form{
margin-right: 0 !important;
box-shadow: none !important;
border: none !important;
margin-bottom: -20px !important;
padding: 0 !important;

}
.lcontent>input,.rcontent>input{margin-bottom: 10px;margin-left: 0 !important;}
.lcontent,.rcontent{
    flex-direction: column;
    justify-content: flex-end;
}
}