.header{
max-width: 100%;
color:var(--fblack);
font-size: 16px;
position: absolute;
z-index: 100;
overflow: hidden;
display: flex;
height: 5rem;
max-height: 5rem;
margin:0 auto;
width: 100%;
align-items: center;
padding: 2rem;
justify-content: space-between;
}
.fixed{
position: fixed;

// Now Give Color to fixed position
.header{
 background: var(--white);
 box-shadow: var(--box-shadow);
 transition: all .5s ease-in-out;

 .active{
    color: var(--m);
    transition: .4s ease;
    border-bottom: 2px solid var(--m);
    }
}


.cart,
.cart-total,
li,
a{
color: var(--fblack) ;
// font-weight: 600;
font-size: 13px;
}
.left-ul .cart:hover,
.left-ul li:hover,
.left-ul a:hover,
.right-ul li:hover,
.right-ul a:hover{
// color: var(--gray);
opacity: .8;
// font-weight: 600;
font-size: 13px;
transition: 0.4s ease;
}
.active{
    color: var(--gray);
    transition: .4s ease;
    border-bottom: 2px solid var(--gray);
    }
}
.logo-cont{
 height: 100%;
 width: 20%;
 min-width: 100px;
 display: flex;
 align-items: center;
 justify-content: flex-start;
 gap:1rem;
}
.logo-cont img{
// transform: scale(1.1);
width: 100px;
}
nav{
display: flex;
width:75%;
justify-content: space-evenly;
}
.right-ul{
 display: flex;
 flex:1;
 li,a{
    color: var(--fblack);
    font-weight: 600;
    font-size: 13px;}
 a:hover{
//   color: var(--mag);
opacity: .8;
  transition: ease 0.4;
 }
 .active{
 color: var(--mag);
 transition: .4s ease;
 border-bottom: 2px solid var(--mag);
 }
}
ul{
    display: flex;
    gap:1rem;
    list-style-type: none;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.left-ul{
display: flex;
flex:1;
justify-content: flex-end;
li,a{
    color: var(--fblack);
    font-weight: 600;
    font-size: 13px;}
ul{
    gap: 1.3rem;
}

li:hover,
a:hover,
.cart:hover{
opacity: .8;
transition: 0.4s ease;
}
}
.cart {
display: flex;
gap:0;
border-radius: 3em;
justify-content: center;
align-items: center;
height: 100%;
// margin: 0 5px;
color:#131313;
flex-direction: column-reverse;
}
.basket{
display: flex;
position: relative;

}
.cart-no{
color:var(--fblack);
position: absolute;
right:-12px;
top:-12px;
width: 20px;
height: 20px;
border-radius: 50%;
display: flex;
align-items: center;
justify-content: center;
font-weight: bold;
font-size: 12px;
}
.cart-total{
font-weight: 600;
color:var(--m);
font-size: 12px;
}
.l5{align-items: center;display: flex;}
@media screen and (max-width: 1024px) {
    .header,.relative-header{
     font-size: 14px;
     height: 70px !important;
     max-height: 70px !important;
    }
    .cart-total,.cart-no{
    font-size: 11px !important;
    // font-weight: normal !important;
    }
    .right-ul{
        display: none;
    }
    .left-ul> ul>.l1,
    .left-ul> ul>.l2,
    .left-ul> ul>.l3,
    .left-ul> ul>.l5{
        display: none !important;
    }
}
@media screen and (max-width: 350px) {
    .left-ul> ul>.l1,
    .left-ul> ul>.l2,
    .left-ul> ul>.l3,
    .left-ul> ul>.l5{
        display: none !important;
    }
}
.relative-header{
position: relative;
top:0;
left: 0;
background: var(--white);
max-width: 100%;
color:var(--fblack);
font-size: 16px;
overflow-x: hidden;
display: flex;
height: 5rem;
max-height: 5rem;
margin:0 auto;
width: 100%;
align-items: center;
// box-shadow: var(--box-shadow);
border-bottom: 1px solid var(--gray);
padding: 1.3rem;
justify-content: space-between;


.active{
    color: var(--mag) ;
    transition: .4s ease;
    border-bottom: 2px solid var(--mag);
    }
}

.fixed{
    position: fixed;
    top: 0;
    left: 0;
    transition: all .5s ease-in-out;
    width: 100%;
    z-index: 100;
    background-color: var(--white);
    color: var(--fblack);
    box-shadow: var(--box-shadow);
    font-weight: 500;

    .cart-total{color: var(--mag);}
}