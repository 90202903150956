
 .carousel{
overflow-x: scroll;
overflow-y: hidden !important;
margin-bottom: 4rem;
 }
.carouselItem {
  width: 270px;
  height: 400px;
    margin: 5px;
    background: #fff;
    box-shadow: var(--box-shadow);
    border-radius: .3em;
    overflow: hidden;
  }

  .product--image {
    cursor: pointer;
    object-fit: cover;
    min-width: 100%;
    max-width: 100%;
    min-height: 70%;
    max-height: 70%;
  }
  .brand-name{
  text-transform: capitalize;
  color: var(--m);
  font-weight: 400;
  line-height: 1.6rem;
  font-family: var(--font-family2);
  }
  .product-short-info{
    min-height: 30%;
  padding: 0px .5rem 0px .5rem;


  h4{
    text-transform: capitalize;
    font-size: 14px;
    color: #333;
    font-weight:600;
    text-transform: capitalize;
    line-height:1.8rem;
  }
  }
  .price {
    font-size: 15px;
    b{
      font-weight: 400;
      line-height: 2rem;
      color: var(--m);
    }


  }
  .quantity{
    display: flex;
    align-items: center;
    margin-left: 10px;
    color: var(--fblack);
  }
  .cart-sw{
  color: var(--grey);

    &:hover{
      color: var(--black);
      transition: .4s ease-in-out;
    }
  }

  
  
  @media screen and (max-width: 1024px) {
  .carouselItem{
    width: 230px;
    height: 300px;

    h4{
      font-size: 12px;
        line-height: 12px;
    }
    .rview{
      margin-top: 4px;
    }
    del{
      font-size: 12px;
    }
    .price {
      b{
        line-height: 1rem;
      }
  
    }
  }
  .product--image {
    min-height: 65%;
    max-height: 65%;
  }
  .product-short-info{
    min-height: 35%;
    padding: 0 .5em 0 .5em;
  }
}
