
   @import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;&display=swap'); 
  @import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap');

  :root{
    --m:rgb(245, 93, 174);
    --f:#8cb4f5;
--mag:rgb(236, 38, 143);
--lmag:rgb(244, 176, 212);
/* --white:#f0f0f0; */
--white:#fff;
--gray:#d3d3d3;
/* --gray:#727586; */
--lightgray:#f7f7f7;
--lgray:#f3f3f3;
--grey:#868585;
--black:#242323;
--fblack:#222222;
--box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
--font-family: 'Inter', sans-serif;
--font-family2: 'Roboto Condensed', sans-serif;
}
body,html{
  overflow-x: hidden !important;
  width: 100%;

}
*{
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-family: var(--font-family);
  font-weight: normal;
  padding: 0;
  margin: 0;
  -webkit-tap-highlight-color:transparent;
  line-height: 1.4em;
  /* user-select: none; */
  position: relative;
}
.--form-control > *{
margin: 5px 0;
}
.--form-control input{
  font-size: 16px;
  font-weight: 400;
  padding:8px 1rem;
  border: 1px solid #777;
  border-radius: 3px;
  outline: none;
}
.--form-control select{
  font-size: 16px;
  font-weight: 400;
  padding:8px 1rem;
  border: 1px solid #777;
  border-radius: 3px;
}
.--form-control label{
  font-size: 16px;
  font-weight: 400;
  min-width: 7rem;
  color: var(--black);
  margin-right: 1rem;
}
b{
  font-weight: bold;
}
.--my{
margin-top: 10px;
margin-bottom: 10px;
}
.--100vh {
  height: 100vh;
}
.--bg-light {
  background: #fff;
}
.--bg-fblack{
  background: var(--fblack);
}
.--relative{
width: 100vw;
height: 100vh;
position: relative;
top:0;
left: 0;
/* overflow: hidden; */
}

a{
  text-decoration: none;
  cursor: pointer;
} 
.--cursor{
  cursor: pointer;
}
.--color-danger{
  color: tomato;
}
.--color-primary{
color: var(--mag);
}
.--color-grey{
  color: var(--grey);
}
/* Card */
.--card {
  border: 1px solid transparent;
  border-radius: 5px;
  box-shadow: var(--box-shadow);
  overflow: hidden;
}
.--center-all{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin: auto;
  text-align: center;
}
.--dotted-lines{
width: 100%;
border-bottom: 1px dashed var(--gray);
display: block;
}
.--underline{
  text-decoration: underline;
}
.--box-underline{
  border-bottom: 1px solid var(--gray);
  margin-top: 6px;
  margin-bottom: 6px;
}
.--align-center{
text-align: center;
}
.--display-none{
display: none !important;
}
.--flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.--flex-col{
display: flex;
flex-direction: column;
}
.--items-center{
display: flex;
align-items: center;
}
.--justify-center{
display: flex;
justify-content: center;
}
.--flex{
display: flex;
}
.--flex-between{
display: flex;
justify-content: space-between;
align-items: center;
}
.--flex-start{
display: flex;
justify-content: flex-start;
align-items: flex-start;
}
.--flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.--dir-column{
flex-direction: column;
}
.--gp-small{
gap: .5rem;
}

.--roboto{
font-family: var(--font-family2);
}
.--uppercase{
text-transform: uppercase;
}
.--bg-gray{
background: var(--gray);
}
.--bg-lightgray{
background: #f7f7f7;
}
.--bg-primary{
background: var(--m);
}
.--bg-danger{
background: tomato;
}
.--bg-green{
  background: green;
}
section{
 /* width: 100%; */
 padding: 1rem .7rem 1rem .7rem ;
/* position: relative; */
/* min-height: 100vh;
overflow-x: hidden;
overflow-y: scroll; */
/* width: 100vw;
min-height: 100vh;
max-height: 100vh;
position: relative;
top:0;
left: 0; */
}
.--f12{
  font-size: 12px;
}
.--f14{
  font-size: 14px;
}
.--f16{
  font-size: 16px;
}
.--fw-thick{
font-weight: 600;
}
.--fw-thin{
  font-weight: 300;
}
.--fw-m{
font-weight: 400;
}
.--mb2{
margin-bottom: 2rem;
}
.--my{
  margin-top: 20px;
}
.--mt10{
  margin-top: 10px;
}
.--my10{
margin-top: 10px;
margin-bottom: 10px;
}
.--mr{margin-right: 20px;}
.--btn{
  font-size: 14px;
  padding: 6px 8px;
  margin: 0 5px 0 0;
  border: 1px solid transparent;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all .3s;
  gap:.3em;
  border-radius: .2em;
  min-height: 40px;
}
.--btn:hover{
  transform: translateY(-2px);
}
.--btn-block{
width: 100%;
}
.--btn-primary{
color:#fff;
background: var(--mag);
}
.--btn-danger{
  background: tomato;
  color:#fff;
}
.--color-lightgray{
  color: var(--lightgray);
}
.--color-green{
color: green !important;
}
.--color-black{
  color: #333 !important;
}
.--color-gray{
color: var(--gray);
}
.--inline-link{
color: var(--mag);
cursor: pointer;
text-decoration: none;
}
.--inline-link:hover{
text-decoration: underline;
transition: .4s ease-in-out;
}

input[type=checkbox]{
  accent-color: var(--mag);
}
::placeholder{
  color: var(--grey);
font-size: 14px;
}
/* For Search box x */
 /* clears the 'X' from Internet Explorer */
 input[type=search]::-ms-clear {  
  display: none; 
  width : 0; 
  height: 0; 
  }
  input[type=search]::-ms-reveal { 
  display: none; 
  width : 0; 
  height: 0; 
  }
 
 /* clears the 'X' from Chrome */
 input[type="search"]::-webkit-search-decoration,
 input[type="search"]::-webkit-search-cancel-button,
 input[type="search"]::-webkit-search-results-button,
 input[type="search"]::-webkit-search-results-decoration { 
 display: none; 
 }
/* Ends.. */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number]{
appearance: textfield;
}

/* Confirm Alert */
.react-confirm-alert-body > h1{
color: var(--m);
font-size: 18px;
}
.react-confirm-alert-button-group{
  height: 40px;
}
.react-confirm-alert-button-group > button{
  font-size: 14px !important;
}
.react-confirm-alert-button-group > button:first-child{
  background: var(--fblack) !important;
}
.react-confirm-alert-button-group > button:last-child{
  background: var(--gray) !important;
  color: #242323;
}
/* React Quill */
.ql-editor{
  min-height: 200px !important;
}
/*  */
/* Pagination */
.pagination{
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
  font-size: 14px;
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
}
.pagination .page-num{
  cursor: pointer;
  border-radius: 3px;
  font-weight: normal;
  color:var(--grey);
  border:1px solid var(--gray);
  margin: 2px;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 3em;
  height: 30px;
  width: 30px;
}
.pagination .page-num:hover{
  color:var(--black);
  border-color:var(--black) !important;
}
.activePage{
  color:var(--black) !important;
  border-color:var(--black) !important;
  height: 100%;
}
/* Product button color */
.btnStyle{
width: 30px;
height: 30px;
margin-right: .7rem;
margin-bottom: .7rem;
border: none;
outline: none;
cursor: pointer;
border-radius: 50%;
align-items: center;
justify-content: center;
display: flex;
/* border: .5px solid #242323; */
}
.btnActive{
  opacity: 1;
}
.btnActive::before{
  content: "";
  z-index: -1;
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px outset var(--fblack);
 padding: 6px;
}
.checkStyle{font-size: 1rem;color:var(--white);z-index: 1 ;position:absolute;}
.price-percent{
font-size: 9px;
color: #f7f7f7 !important;
border-radius: 3rem;
padding: 2px 4px 2px 4px;
height: 15px;
}
/* Pagination Ends */
@media screen and (max-width: 800px) {
  .ph-title{font-size: 1.5rem !important; }
}
@media screen and (max-width: 600px) {
  .--flex-dir-column {
    flex-direction: column;
  }
}
@media screen and (max-width: 200px) {
  *{display: none;}
}
