.search{
height: 40px;
// width: 100%;
input[type=search]{
height: 100%;
width: 100%;
padding-left: 6px;
border-radius: .3em;
// border: none;
outline: none;
}
}
// @media screen and (max-width:800px) {
//     .search{width: 100%;}
// }