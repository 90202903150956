.category{
  display: flex;
  flex-direction: column;
}
.filter {
.flabel{
  font-weight: 300;

  &:first-of-type{
    margin-top: 4px;
  }
  &:last-of-type{
    margin-bottom: 8px;
  }
}
    h4 {
      margin-top: .3rem;
      margin-bottom: .3rem;
      font-size: 14px;
    }
  
    .brand {
      select {
        font-weight: 400;
        padding: 5px;
       min-height: 40px;
        width: 80%;
        border: none;
        border-radius: 3px;
        outline: none;
      }
    }
  }
  
  .price {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .select{
    height: 40px;
    margin-top: 8px;
    width: 100%;
    padding-left: 8px;
    border-radius: .3em;
  }
  

  