.p-dashboard{
background: #fff;
width: 100%;
padding: 1rem 0 1rem 0;
&>h3{
    padding-bottom: 8px;
}
}

.dash-two-item{
width: 100%;
display: flex;
justify-content: space-between;
margin-right: 20px;
}
.p-dash-count{
background: #f7f7f7;
height: 100px;
display: flex;
align-items: center;
justify-content: center;

h3{
margin-left: 10px;
font-size: 20px !important;
color: #333;

}
}
.count-circle{
padding: 8px;
border-radius: 50%;
height: 50px;
width: 50px;
display: flex;
align-items: center;
background: #fff;
color: var(--gray);
}
.dash-order-stat:first-child{margin-right: 20px;}
.dash-order-stat{
width: 50%;
background: #fff;
padding: 12px;
border-radius: .5em;
h3{
    font-size: 14px;

}
a{
    font-size: 14px;
    border: 1px solid var(--gray);
    padding: 8px;
    border-radius: .3em;
    color: var(--grey);
    font-weight: 400;
&:hover{
    border-color: #000;
    transition: .6s ease;
    color: #000;
    transform: translateY(-2px);

}
}
}
.bottom-flex>span{
align-items: center;
display: flex;
margin-top: 6px;
font-size: 14px;
text-transform: capitalize;
margin-right: 12px;
color: var(--fblack);
}
.dash-transact{
width: 100%;
min-height: 17rem;
margin-top: 20px;
margin-right: 20px;
background: #fff;
border-radius: .5em;
padding: 12px;
overflow-x: scroll;
}
.right-dash-stat{
background: #fff;
min-height: 70vh;
max-height: 70vh;
width: 300px;
max-width: 300px;
border-radius: .5em;
}

.dash-order-tab{
width: 100%;
height: 13rem;
display: flex;
align-items: center;
justify-content: center;

.dash-btn{
    border: 1px solid var(--gray);
    font-size: 14px;
    color: var(--grey);
    font-weight: 400;
    min-height: 40px;

    &:hover{
     color: var(--black);
     border: 1px solid var(--black);
     transition: .6s ease;
    }
}
}
@media screen and (max-width: 800px) {
.dash-two-item{
flex-direction: column ;
}
.dash-order-stat{
width: 100% ;

}
.dash-order-stat:last-child{
margin-top: 20px;
}
.dash-order-stat:first-child{
margin-right: 0px;
}
.dash-order-tab{
flex-direction: column;
}
.dash-btn:last-child{
margin-top: 20px;
}
.p-dashboard{
    padding: 0;
}
}