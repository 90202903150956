.charts {
    width: 100%;
    max-width: 50vw;
}
    .card {
      padding: 1rem;
      border: 1px solid #ccc;
      border-bottom: 3px solid tomato;
    }
    @media screen and (max-width:1000px) {
      .charts{
        max-width: calc(100vw - 20px) !important;
        margin-left: 0 !important;
      }
    }
  