.t-details{
width: 100%;
height: 100%;
position: fixed;
top: 0;
left: 0;
z-index: 105;
background: rgba($color: #000000, $alpha: 0.99);
}
.td-wrapper{
width: 100%;
height: 100%;
display: flex;
align-items: center;
justify-content: center;
}
.td-cont{
width: 400px;
max-width: 400px;
background: var(--white);
padding: 20px;
font-size: 13px;
}
.td-head{
width: 100%;
display: flex;
align-items: center;
justify-content: space-between;

h4{
font-weight: 600;
color: var(--black);
font-size: 14px;
}
}
.brandcont{
img{
width: 35px;
}
}
.td-amount{
width: 100%;
height: 60px;
margin-top: 40px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
border-bottom: 1px solid var(--mag);
padding-bottom: 20px;
}
.full-amount{
display: flex;
align-items: center;
font-size: large;
color: var(--mag);
h3{
font-weight: 500;
}
}
.td-status{
font-size: small;
color: green;
}
.tdate{
color: var(--fblack);
margin-top: 4px;
}

.per-transact{
display: flex;
justify-content: space-between;
height: 40px;
align-items: center;
color: var(--grey);
h4{
    color: var(--fblack);

}
span{
// font-weight: bold;
font-size: 13px;
}
}
.tdname{
text-align: right;
}
.td-support{
width: 100%;
margin-top: 20px;
margin-bottom: 20px;
text-align: center;
span{
    color: var(--fblack);
    font-weight: 500;
}
}

// @media screen and (max-width:600px) {
// .td-cont{
//     font-size: 12px;
// }
// }