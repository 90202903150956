.verify-email{
// background: var(--lmag);
min-height: 100vh;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
font-size: 12px;
h2{font-weight: bold;}
.vcontent{
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
padding: 20px;
h3{
 font-weight: bold;
 color: var(--mag);
 margin-bottom: 10px;
}
a{
 text-decoration: underline;
 color: var(--mag);
}
}

}