.role-form{
    height: 40px;
    width: 200px;

    .role-select{
        height: inherit;
        width: 160px;
    }
    .role-btn{
        width: 40px !important;
        margin-left: 5px;
    }
}